<template>
  <div class="edit-transformer">
    <CForm
      ref="editTransformer"
      v-on:submit.prevent="editTransformer"
      novalidate
      class="edit-transformer-form"
    >
      <!--   HEADER   -->
      <CreateHeader
        :loading="loading"
        :descriptions="descriptions"
        :title="texts.editPage.title"
        submit-title="update"
      />
      <!--  CONTENT   -->
      <div class="form-content content">
        <!--     ID   -->
        <ClFormRow
          :label="texts.editPage.idLabel"
          :toolTipText="descriptions.id"
        >
          <template #content>
            <CInput
              :placeholder="texts.editPage.idPlaceholder"
              v-model="formData.id"
              add-input-classes="col-sm-12"
              disabled="true"
            />
          </template>
        </ClFormRow>
        <!--  Created    -->
        <ClFormRow
          :label="texts.editPage.createdLabel"
          :toolTipText="descriptions.created"
        >
          <template #content>
            <CInput
              :value="created"
              add-input-classes="col-sm-12"
              required
              disabled="true"
            />
          </template>
        </ClFormRow>
        <!--     Space Name   -->
        <ClFormRow
          :label="texts.editPage.spaceNameLabel"
          :toolTipText="descriptions.spaceName"
        >
          <template #content>
            <CInput
              :value="formData.spaceName"
              add-input-classes="col-sm-12"
              disabled="true"
            />
          </template>
        </ClFormRow>
        <!--     NAME   -->
        <ClFormRow
          :label="texts.editPage.nameLabel"
          :toolTipText="descriptions.name"
        >
          <template #content>
            <CInput
              :placeholder="texts.editPage.namePlaceholder"
              v-model="formData.name"
              add-input-classes="col-sm-12"
              required
              :invalidFeedback="texts.invalidFeedback"
              :is-valid="nameValidate"
            />
          </template>
        </ClFormRow>
        <!--   Source Language     -->
        <ClFormRow
          :label="texts.editPage.sourceLanguageLabel"
          :toolTipText="descriptions.sourceLanguage"
        >
          <template #content>
            <multiselect
              v-model="formData.sourceLanguage"
              :options="options"
              :class="selectErrorClass"
              class="custom-multi-select zq--multiselect  zq--multiselect__select zq--multiselect__green"
              :searchable="false"
              :multiple="false"
              :preserve-search="true"
              :taggable="false"
              required
              :selectLabel="selectLabel"
              :deselectLabel="selectLabel"
              :invalidFeedback="texts.invalidFeedback"
              :is-valid="sourceLangValidate"
              :allow-empty="false"
              :block-keys="['Tab', 'Enter']"
            />
          </template>
        </ClFormRow>
        <!--   Source code     -->
        <ClFormRow
            :label="texts.editPage.sourceCodeLabel"
            :toolTipText="descriptions.sourceCode"
        >
          <template #content>
            <codemirror
              v-model="formData.source"
              :options="cmOptions"
            />
          </template>
        </ClFormRow>
      </div>
    </CForm>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import routerBreadcrumbs from '@/router/breadcrumb/routerBreadcrumbs';
import {cloneDeep} from "lodash";
import {dateFormate} from "@/utils/dateFormate";
import ClFormRow from "@/shared/components/formComponents/ClFormRow";
import CreateHeader from "@/shared/components/CreateHeader";
import {formConfig} from "@/config";
import {transformers} from "@/config/descriptions/transformers"
import {transformersTexts} from "@/config/pageTexts/transformers.json";

export default {
  name: 'EditTransformer',
  components: {
    ClFormRow,
    CreateHeader,
  },
  data() {
    return {
      descriptions: {
        ...transformers.edit
      },
      texts: {
        ...transformersTexts
      },
      selectLabel: formConfig.selectLabel,
      formData: {
        id: '',
        created: '',
        name: '',
        sourceLanguage: '',
        source: '',
      },
      options: ['Scala', 'test lang1', 'test lang2'],
      nameValidate: null,
      sourceLangValidate: null,
      cmOptions: {
        tabSize: 4,
        styleActiveLine: false,
        lineNumbers: true,
        styleSelectedText: false,
        line: true,
        foldGutter: true,
        lineWrapping: true,
        gutters: ['CodeMirror-linenumbers', 'CodeMirror-foldgutter', 'CodeMirror-lint-markers'],
        hintOptions:{
          completeSingle: false
        },
        matchBrackets: true,
        showCursorWhenSelecting: true,
        extraKeys: { "Ctrl": "autocomplete" }
      },
    };
  },
  computed: {
    ...mapGetters('transformers', ['success', 'message', 'loading', 'transformer']),
    ...mapGetters('theme', ['theme']),
    selectErrorClass() {
      const className = this.theme === 'main' ? 'error-validation--multiselect' : 'error-validation';

      return {
        [className]: (this.sourceLangValidate === false)
      }
    },
    created() {
      return dateFormate(this.formData.created)
    }
  },
  created() {
    this.initialize();
    routerBreadcrumbs(this.$router.currentRoute);
  },
  watch: {
    transformer() {
      this.formData = cloneDeep(this.transformer);
    },
    formData: {
      deep: true,
      handler: function (val) {
        if (val.name) this.nameValidate = true;
      },
    },
  },
  methods: {
    ...mapActions('transformers', ['handleUpdateTransformers', 'handleGetTransformers_item']),
    initialize() {
      this.handleGetTransformers_item([this.$route.params.id],1,0)
        .then(data => {
          routerBreadcrumbs(this.$router.currentRoute, {
            name: data[0].name
          });
        });
    },
    editTransformer() {
      this.nameValidate = !!this.formData.name;
      this.sourceLangValidate = !!this.formData.sourceLanguage;
      const body = [
        {
          id: this.formData.id,
          source: this.formData.source,
          sourceLanguage: this.formData.sourceLanguage,
          name: this.formData.name,
        },
      ];
      if (this.nameValidate && this.sourceLangValidate) {
        this.handleUpdateTransformers({updateTransformerRequestArray: body})
          .then(data => {
            if (data.length) {
              this.$router.push({
                name: 'PreviewTransformer',
                params: {
                  id: data[0].id,
                  name: data[0].name,
                }
              })
            }
          })
      }
    },
  },
};
</script>
<style lang="scss">
.edit-transformer {
  height: 100%;
  .edit-transformer-form {
    height: calc(100% - 50px);
    .form-content {
      background: var(--zq-sub-bg);
      height: 100%;
      border-top: 1px solid var(--zq-content-border-color);
      .custom-multi-select.zq--multiselect.zq--multiselect__select {
        .multiselect__select {
          opacity: 1;
        }
      }
    }
  }
}
</style>